const ShopBannerContent = () => {
  // check if cookie exists, if it does, do not show banner
  if (document.cookie.indexOf("tv_maci_banner") >= 0) {
    return;
  }

  // show banner
  $(".tv-maci-banner-container").removeClass("hidden");

  // attach close listener
  $("#close_tv_maci_banner").click(function() {
    $(".tv-maci-banner-container").addClass("hidden");

    // set a cookie that lasts for 1 day
    const date = new Date();
    date.setDate(date.getDate() + 1);

    document.cookie = "tv_maci_banner=1; expires=" + date.toUTCString() + "; path=/";
  });
};

const ShopBanner = (function (){
  return {
    init: () => {
      ShopBannerContent();
    }
  }
})();

export default ShopBanner;
